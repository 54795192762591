const experiences = [
  {
    title: "Diplôme d'ingénieur en Informatique et Gestion",
    date: "2010 - 2013",
    company: "Polytech'Montpellier",
    description: "Développement de logiciels, gestion d'entreprise, conception de systèmes d'information, bases de données, algorithmique, NTIC.",
  },
  {
    title: "Master 2 (en double diplôme) en Système d'information économique",
    date: "2012 - 2013",
    company: "Université Montpellier I",
    description: "Économie, informatique, business intelligence, bases de données, économétrie, probabilité/statistique.",
  },
]

export default experiences
