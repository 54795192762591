const experiences = [
  {
    title: "Application mobile Android / iOS",
    date: "Depuis 2020",
    company: "Exod",
    work: "Réalisation d'une application mobile Android et iOS en Flutter et de son API REST en Symfony.",
    description: "Exod est un réseau social sur lequel on peut trouver, suggérer et partager des activités et évènements autour de nous.",
    link: "https://exod.app/",
    technologies: ['flutter', 'dart', 'mobx', 'symfony', 'php', 'ios', 'elasticsearch', 'android'],
  },
  {
    title: "Plateforme LMS",
    date: "Depuis 2020",
    company: "Easy Access English",
    work: "Maintenance et évolution d'une plateforme LMS (Learning management system) en Symfony.",
    description: "Easy Access English est une école d'anglais disposant de son propre LMS permettant la gestion courante des activités de l'entreprise, mais surtout les spécificités de la méthode d'apprentissage inventée par Easy Access English.",
    link: "https://easyaccessenglish.com/",
    technologies: ['symfony', 'php', 'javascript'],
  },
  {
    title: "Plateforme de réservation",
    date: "Depuis 2019",
    company: "My Sport Session",
    work: "Réalisation et évolution d'une plateforme de réservation de séances de sport en Symfony et ReactJS/NextJS.",
    description: "My Sport Session est une société qui propose aux clubs, coachs, salles de sport, un logiciel de gestion de planning et de réservation. Il se compose de 2 interfaces distinctes. Une première pour le club afin qu'il renseigne son planning et qu'il suive les réservations. Une seconde pour les sportifs afin qu'ils trouvent et réservent les séances de sport de leurs choix.",
    link: "https://www.mysportsession.com/",
    technologies: ['symfony', 'php', 'nextjs', 'react', 'redux', 'javascript'],
  },
  {
    title: "Méta-moteur de recherche & plateforme d'affiliation",
    date: "Depuis 2018",
    company: "Doyoogo",
    work: "Réalisation et évolution d'un méta-moteur de recherche d’activités et d’expériences de voyage et d'une plateforme d'affiliation permettant la diffusion de ces produits",
    description: "Doyoogo agrège les données d'un grand nombre d'acteurs du voyage (essentiellement des billets pour des activités touristiques) au sein d'un puissant moteur de recherche et dispose d'une plateforme d'affiliation et d'un ensemble de widgets permettant aux annonceurs \"voyage\" de monétiser leur contenu avec publicités particulières ciblés. En quelques chiffres, Doyoogo agrège 32 fournisseurs, possède 450k produits et affiche 4,5M de widgets par mois.",
    link: "https://doyoogo.com/",
    technologies: ['symfony', 'php', 'react', 'redux', 'javascript', 'elasticsearch', 'golang', 'rabbitmq'],
  },
  {
    title: "Site de e-commerce",
    date: "2019",
    company: "Le Panier Fermier Aveyronnais",
    work: "Réalisation et maintenance d'un site de e-commerce sous Wordpress et WooCommerce.",
    description: "Le Panier Fermier Aveyronnais propose un large choix de produits du terroir fabriqués en Aveyron par de petits producteurs. Les ventes se font en ligne soit en livraison, soit en click and collect.",
    link: "https://www.lepanieraveyronnais.fr/",
    technologies: ['wordpress', 'woocommerce'],
  },
  {
    title: "Plateforme de petites annonces",
    date: "2018",
    company: "Atome Auto",
    work: "Maintenance et évolution de la plateforme en Symfony et DustJS",
    description: "Atome Auto est une plateforme de petites annonces automobiles, comprennant un réseau social et interface pour enregistrer toutes les informations techniques et de suivis de son véhicule.",
    technologies: ['symfony', 'php', 'javascript', 'dustjs'],
  },
  {
    title: "Plateforme de réservation",
    date: "De 2013 à 2018",
    company: "Bedycasa",
    work: "Maintenance et évolution d'une plateforme de réservation en ligne de logements chez l'habitant.",
    description: "Bedycasa est une société pionière de l'économie collaborative. Dès 2007, elle proposait à des hébergeurs de mettre à la location une partie de leur logement et à des voyageurs de trouver et réserver en ligne des logements chez l'habitant à des tarifs compétitifs. La plateforme revendiquait plus de 400k membres et 60k logements en 2018.",
    technologies: ['symfony', 'php', 'react', 'redux', 'javascript', 'elasticsearch'],
  },
  {
    title: "Sites vitrines",
    work: "Réalisation et maintenance de sites vitrines.",
    description: "Je réalise occasionnellement des sites vitrines pour des artisans et autres professions libérales.",
    links: [
      ['https://www.velezdelacalle.fr/', 'Elena Velez de la Calle - Avocate au Barreau de Paris'],
      ['https://boudet-rue.com/', 'BOUDET & RUE - Avocats au Barreau de Paris'],
      ['https://loustaldesbonsvivants.com/', 'L\'Oustal des Bons Vivants - Chambres d\'hôtes'],
      ['https://www.isatelle-avocat.fr/', 'Sylvain ISATELLE - Avocat à Montpellier'],
    ],
    technologies: ['wordpress', 'gatsby'],
  },
]

export default experiences
