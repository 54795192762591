import * as React from "react"
import "../styles/style.scss"
import Particles from "react-tsparticles"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import experiences from "../data/experiences"
import formations from "../data/formations"
import technos from "../data/technos"
import { faSymfony, faJs, faPhp, faReact, faAndroid, faApple, faWordpress, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faQuestion, faSearch, faShoppingCart, faLayerGroup, faHome, faEnvelope, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core"
import logoCobalty from "../images/logo-full-neutre-white.png"
import mePicture from "../images/me.jpg"
import backgroundBooks from "../images/background-books.jpg"
import backgroundMarble from "../images/background-marble.png"
import backgroundComputer from "../images/background-computer.jpeg"
import "@fontsource/roboto/100.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/700.css"

library.add(faSymfony)
library.add(faJs)
library.add(faPhp)
library.add(faReact)
library.add(faApple)
library.add(faAndroid)
library.add(faQuestion)
library.add(faSearch)
library.add(faWordpress)
library.add(faShoppingCart)
library.add(faLayerGroup)
library.add(faEnvelope)
library.add(faLinkedin)
library.add(faHome)
library.add(faUserCircle)

// markup
const IndexPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>COBALTY | Creative & Tech Agency</title>
        <meta name="description"
              content="Expertise Symfony2 / React / Flutter / ElasticSearch. Agence spécialisée dans le développement web sur mesure et l'accompagne technique de startups et PME, de la conception à la réalisation."/>
        <link rel="canonical" href="https://www.cobalty.com"/>
        <script>
          {`window.$crisp=[];window.CRISP_WEBSITE_ID="8ef17759-edad-47aa-b10e-25eb70001c08";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}
        </script>
      </Helmet>
      <nav id="navbar" className="navbar navbar-light fixed-top px-3">
        <a className="navbar-brand" href="#intro"><img src={logoCobalty} alt="Logo" height="40" /></a>
        <ul className="nav nav-pills">
          <li className="nav-item d-none d-md-block">
            <a className="nav-link" href="#intro">Intro</a>
          </li>
          <li className="nav-item d-none d-md-block">
            <a className="nav-link" href="#about-anchor">À Propos</a>
          </li>
          <li className="nav-item d-none d-md-block">
            <a className="nav-link" href="#experiences-anchor">Expériences</a>
          </li>
          <li className="nav-item d-none d-md-block">
            <a className="nav-link" href="#competences-anchor">Compétences</a>
          </li>
          <li className="nav-item d-none d-md-block">
            <a className="nav-link" href="#contact-anchor">Contact</a>
          </li>
          <li className="nav-item d-block d-md-none">
            <a className="nav-link" href="#contact-anchor" aria-label="Contact"><FontAwesomeIcon icon={['fas', 'envelope']} /></a>
          </li>
        </ul>
      </nav>
      <div className="container-fluid">
        <div id="intro" className="row align-items-center" style={{background: 'url(' + backgroundMarble + ') no-repeat 50% / cover'}}>
          <div className="col text-center">
            <Particles id="tsparticles" url="./config-particle.json" />
            <div className="particle-wrapper">
              <div className="text-center">
                <img src={logoCobalty} alt="Logo" width="290" />
              </div>
              <h3>
                Accompagnement technique et développement<br />
                Web & Mobile
              </h3>
              <p className="h3 text-green"><b>#Symfony #React #Flutter #ElasticSearch</b></p>
              <p className="h3 text-green"><b>Agréments CII et CIR</b></p>
              <p className="font-light">À Montpellier (et dans le reste du monde)</p>
            </div>
          </div>
        </div>
        {
          // eslint-disable-next-line
        }<a className="anchor" id="about-anchor" />
        <div id="about" className="row">
          <div className="col-2 col-md-3" style={{background: 'url(' + backgroundBooks + ') no-repeat 50% / cover'}}></div>
          <div className="col-10 col-md-9 p-5 font-light">
            <img src={mePicture} alt="Anthony ASTOUL" className="float-end rounded d-none d-md-block" height="200" />
            <h2 className="mb-5"><span className="font-lighter">Je suis</span> <b>Anthony ASTOUL</b></h2>
            <p>
              Depuis près de 10 ans, je suis <b>développeur full stack</b> et développe des <b>applications web et
              mobile</b> sur des projets innovants.
            </p>
            <p>
              Je vous propose mon expertise sur les technologies suivantes :
            </p>
            <ul>
              <li><b>Symfony</b> (+9 ans)</li>
              <li><b>ElasticSearch</b> (+7 ans)</li>
              <li><b>React</b> (+5 ans)</li>
              <li><b>Flutter</b> (+2 ans)</li>
            </ul>
            <img src={mePicture} alt="Anthony ASTOUL" className="rounded d-block d-md-none mx-auto mt-3 mb-3" height="200" />
            <p>Fort de ces expériences, je peux également vous accompagner sur des problématiques de <b>conception, d'infrastructure,
              d'analyse de données, de sécurité, de SEO...</b> J'ai l'habitude de travailler avec les technologies courantes suivantes : Docker, Varnish, Git, CircleCI, RabbitMQ, MySQL, PostgreSQL, Sass, Bootstrap, Twig...</p>
            <p>
              Je peux travailler dans vos locaux dans le secteur de <b>Montpellier</b> mais je travaille également <b>à distance</b>.
            </p>
            <p>
              Depuis 2020, je acquis les <b>agréments CII et CIR</b> qui attestent de ma capacité à développer des projets
              innovants ou de recherche et vous donne la possibilité de bénéficier du crédit d'impôt recherche et/ou innovation.
            </p>
            <p>
              J'ai obtenu un <b>diplôme d’ingénieur</b> en Information et Gestion (Polytech Montpellier) et un <b>Master 2</b> en Système
              d’information économique (Université Montpellier 1)
            </p>
          </div>
        </div>
        {
          // eslint-disable-next-line
        }<a className="anchor" id="experiences-anchor" />
        <div id="experiences" className="row">
          <div className="col p-5">
            <h2>Expériences</h2>
            {experiences.map((e) =>
              <div>
                <hr />
                <h3>{e.title}</h3>
                <p>
                  {!!e.date && (
                    <span className="font-lighter">
                      <i>
                        {e.date} - {!!e.link && <a href={e.link} target="_blank" rel="noreferrer">{e.company}</a>}{!e.link && e.company}
                      </i>
                      <br />
                    </span>
                  )}
                  {!!e.work && (
                    <React.Fragment>
                      {e.work}
                      <br />
                    </React.Fragment>
                  )}
                  {!!e.description && (
                    <span className="font-lighter">
                      {e.description}
                      <br />
                    </span>
                  )}
                </p>
                {e.links && (
                  <ul>
                    {e.links.map(l => (
                      <li><a href={l[0]} target="_blank" rel="noreferrer">{l[1]}</a></li>
                    ))}
                  </ul>
                )}
                {e.technologies.map(t => {
                  let techno = technos[t]
                  if (techno) {
                    return (
                      <span className="badge me-2 mb-2">
                        {!!techno.iconClass && <FontAwesomeIcon icon={techno.iconClass} className="me-1" />}
                        {techno.title}
                      </span>
                    )
                  }
                  return null
                })}
              </div>
            )}
          </div>
        </div>
        {
          // eslint-disable-next-line
        }<a className="anchor" id="competences-anchor" />
        <div id="competences" className="row">
          <div className="col-10 col-sm-9 p-5">
            <h2>Formation</h2>
            {formations.map((e) =>
              <div>
                <hr />
                <h3>{e.title}</h3>
                <p>
                  {!!e.date && (
                    <span className="font-lighter">
                      <i>{e.date} - {e.company}</i>
                      <br />
                    </span>
                  )}
                  {!!e.description && (
                    <React.Fragment>
                      {e.description}
                      <br />
                    </React.Fragment>
                  )}
                </p>
              </div>
            )}
          </div>
          <div className="col-2 col-sm-3" style={{background: 'url(' + backgroundComputer + ') no-repeat 50% / cover'}} />
        </div>
        {
          // eslint-disable-next-line
        }<a className="anchor" id="contact-anchor" />
        <div id="contact" className="row" style={{background: 'url(' + backgroundMarble + ') no-repeat 50% / cover'}}>
          <div className="col p-5">
            <h2>Contact</h2>
            <form name="contact" method="POST" data-netlify="true" data-netlify-recaptcha="true">
              <input type="hidden" name="form-name" value="contact" />
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="name">Votre nom: </label>
                    <input type="text" name="name" className="form-control" />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="email">Votre Email:</label>
                    <input type="email" name="email" className="form-control" />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="message">Message:</label>
                <textarea name="message" className="form-control" />
              </div>
              <div data-netlify-recaptcha="true"></div>
              <div className="mb-3">
                <button type="submit" className="btn btn-primary">Envoyer</button>
              </div>
            </form>
          </div>
        </div>
        <div id="footer" className="row">
          <div className="col-12 col-md p-5">
            <p className="h3 mb-5"><b>COBALTY | Creative & Tech Agency</b></p>
            <p className="mb-5 font-lighter">Agence spécialisée dans le développement web et mobile sur mesure et l’accompagne technique de startups et PME, de la conception à la réalisation.</p>
            <p>Expertise Symfony2 / React / Flutter / ElasticSearch</p>
          </div>
          <div className="col-12 col-md p-5">
            <p className="h4 mb-4"><FontAwesomeIcon icon={['fas', 'envelope']} className="me-2" /><a href="mailto:hello@cobalty.com">hello@cobalty.com</a></p>
            <p className="h4 mb-4"><FontAwesomeIcon icon={['fas', 'home']} className="me-2" />Montpellier, FRANCE</p>
            <p className="h4 mb-4"><FontAwesomeIcon icon={['fab', 'linkedin']} className="me-2" /><a href="https://www.linkedin.com/in/anthonyastoul/" target="_blank" rel="noreferrer">LinkedIn</a></p>
            <p className="h4"><FontAwesomeIcon icon={['fas', 'user-circle']} className="me-2" /><a href="https://www.malt.fr/profile/anthonyastoul" target="_blank" rel="noreferrer">Malt</a></p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default IndexPage
