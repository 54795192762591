const technos = {
  "symfony": {
    title: "Symfony",
    iconClass: ["fab", "symfony"],
  },
  "flutter": {
    title: "Flutter",
    //iconClass: ["fas", "question"],
  },
  "dart": {
    title: "Dart",
    //iconClass: ["fas", "question"],
  },
  "golang": {
    title: "Golang",
    //iconClass: ["fas", "question"],
  },
  "rabbitmq": {
    title: "RabbitMQ",
    iconClass: ["fas", "layer-group"],
  },
  "php": {
    title: "PHP",
    iconClass: ["fab", "php"],
  },
  "javascript": {
    title: "Javascript",
    iconClass: ["fab", "js"],
  },
  "dustjs": {
    title: "Dust.js",
    iconClass: ["fab", "js"],
  },
  "react": {
    title: "React",
    iconClass: ["fab", "react"],
  },
  "gatsby": {
    title: "Gatsby",
    iconClass: ["fab", "react"],
  },
  "redux": {
    title: "Redux",
    iconClass: ["fab", "react"],
  },
  "nextjs": {
    title: "Next.js",
    iconClass: ["fab", "react"],
  },
  "mobx": {
    title: "Mobx",
    //iconClass: ["fas", "question"],
  },
  "elasticsearch": {
    title: "ElasticSearch",
    iconClass: ["fas", "search"],
  },
  "android": {
    title: "Android",
    iconClass: ["fab", "android"],
  },
  "ios": {
    title: "iOs",
    iconClass: ["fab", "apple"],
  },
  "wordpress": {
    title: "Wordpress",
    iconClass: ["fab", "wordpress"],
  },
  "woocommerce": {
    title: "WooCommerce",
    iconClass: ["fas", "shopping-cart"],
  },
}

export default technos
